<template>
	<div id="box">
		<div class="box">
			<div class="b-top">
				<h1>政 策 兑 现 查 询</h1>
			</div>
			<div class="conte">
				<div class="c-box">
					<div class="b-left">
						<p>申报状态</p>
						<p>申报部门</p>
						<p>政策年度</p>
						<p>政策月历</p>
					</div>
					<div class="b-right">
						<ul class="brh">
							<li class="bx">不限</li>
							<li>未申报</li>
							<li>申报中</li>
							<li>申报已完成</li>
							<li>其他</li>
						</ul>
						<ul class="brh">
							<li class="bx">不限</li>
							<li>科技创新局</li>
							<li>社会事业局</li>
							<li>经开区工委组织人事部</li>
							<li>城市运行局</li>
							<li>商务金融局</li>
							<li>开发建设局</li>
						</ul>
						<ul class="brh">
							<li class="bx">不限</li>
							<li>2021年</li>
							<li>2021年</li>
							<li>2019年</li>
						</ul>
						<ul class="brh">
							<li class="bx">不限</li>
							<li>一月</li>
							<li>二月</li>
							<li>三月</li>
							<li>四月</li>
							<li>五月</li>
							<li>六月</li>
							<li>七月</li>
							<li>八月</li>
							<li>九月</li>
							<li>十月</li>
							<li>十一月</li>
							<li>十二月</li>
						</ul>
					</div>
				</div>
				<div class="c-bom">
					<div class="bom-l">
						<h2>申报政策</h2>
					</div>
					<div class="bom-r">
						<el-input
						  placeholder="请输入政策名称"
						  v-model="input"
						  clearable>
						</el-input>
						<el-button type="primary">查询</el-button>
					</div>
				</div>
			</div>
			<div class="bottom">
				<div class="bo-px">
					<p>排序选择</p>
					<el-button >最新 <i class="el-icon-bottom"></i></el-button>
					<el-button >时间 <i class="el-icon-bottom"></i></el-button>
					<el-button >热度 <i class="el-icon-bottom"></i></el-button>
				</div>
				<div class="bo-conte">
					<div class="bocc" v-for="(item,i) of boccArr" :key="i">
						<p>{{item.title}}</p>
						<div class="bocc-an">
							<span class="red">剩 {{item.red}} 天</span>
							<span class="blue">{{item.blue}}</span>
						</div>
						<div class="tex">
							<span>{{item.look}}人看过</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				input:'',
				boccArr:[
					{title:'龙港市总部经济企业办公用房补助事项', red:3, blue:'申报', look:384},
					{title:'龙港市引才补贴（个人）', red:3, blue:'申报', look:384},
					{title:'龙港市国家级、省级、市级企业研发中心认定（备案）奖励', red:3, blue:'申报', look:384},
					{title:'龙港市创新券兑现申报指南', red:3, blue:'申报', look:384},
					{title:'龙港市发明专利产业化项目补助', red:3, blue:'申报', look:384},
					{title:'温州市领军型人才创业项目', red:3, blue:'奖项补助', look:384},
					{title:'温州市降低科创型企业融资成本贷款贴息', red:3, blue:'申报', look:384},
					{title:'龙港市农业项目补助资金', red:3, blue:'申报', look:384},
					{title:'龙港市首批“龙城鲲鹏”计划入选者奖补（个人）', red:3, blue:'申报', look:384},
					{title:'龙港市新增高层次人才（ABCD类）奖补（个人）', red:3, blue:'申报', look:384},
					{title:'温州市高校毕业生灵活就业社保补贴', red:3, blue:'申报', look:384},
					{title:'温州市人才个人奖励', red:3, blue:'申报', look:384},
				]
			}
		}
	}
</script>

<style>
	ul>li{
		list-style: none;
	}
	.box .b-top{
		width: 100%;
		height: 60px;
		background: url(../../assets/img/zhengce.png);
		background-size: 100% 100%;
		line-height: 60px;
		text-align: center;
	}
	.b-top>h1{
		font-size: 34px !important;
		color: white;
	}
	.conte{
		width: 100%;
		height: 380px;
		background-color: white;
	}
	.bottom{
		background-color: white;
	}
	.c-box{ 
		height: 280px;
		padding: 40px 25px 10px 25px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}
	.c-bom{
		height: 80px;
		margin: 20px 25px 10px 25px;
		border-bottom: 3px solid #a5a5a5;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.bom-r{
		display: flex;
		justify-content: flex-start;
	}
	.el-input{
		width: 200px;
	}
	.b-left{
		width: 200px;
		height: 100%;
		background-color: #F2F4F5;
		text-align: center;
	}
	.b-right{
		width: 1000px;
		height: 100%;
		background-color: #F9F9F9;
	}
	.b-left>p{
		font-size: 20px;
		font-weight: bold;
		margin: 20px 0 30px 0;
	}
	.b-right .brh{
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin: 22px 0 30px 0;
	}
	.brh>li{
		font-size: 18px;
		margin-left:27px;
		color: #8a8a8a;
	}
	.brh .bx{
		color: #F3B4A5;
	}
	/*  */
	.bo-px{
		padding: 20px 25px 20px 25px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}
	.bo-px>p{
		margin-right: 20px;
		font-size: 20px;
	}
	.bo-px .el-icon-bottom{
		font-weight: 800;
		font-size: 17px;
	}
	.bo-px .el-button{
		border-radius: 0;
		font-size: 15px;
		margin: 0;
		padding: 9px 15px 9px 20px;
	}
	/* .el-button:hover{
		background-color: #00E1DB;
		color: white;
	} */
	.bo-conte{
		display: flex;
		flex-wrap: wrap;
	}
	.bocc{
		height: 160px;
		width: 270px;
		margin: 0 5px 20px 24px;
		background-color: white;
		box-shadow: 0 0 15px #dadada;
	}
	.bocc:hover{
		background-color: #96DAF9;
	}
	.bocc>p{
		padding: 20px 20px 0 20px;
		line-height: 26px;
		font-weight: bold;
		height: 75px;
	}
	.bocc-an{
		position: relative;
		display: flex;
		justify-content: space-between;
		margin: 15px 30px;
	}
	.bocc-an .red{
		display: block;
		width: 70px;
		height: 30px;
		line-height: 30px;
		text-align: center;
		border: 1px solid red;
		color: red;
		cursor: pointer;
	}
	.bocc-an .blue{
		display: block;
		width: 70px;
		height: 30px;
		line-height: 30px;
		text-align: center;
		border: 1px solid blue;
		color: blue;
		cursor: pointer;
	}
	.bocc .tex{
		display: flex;
		justify-content: flex-end;
		margin-right: 30px;
	}
</style>
